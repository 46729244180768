import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
{path:'', loadChildren: './home/home.module#HomeModule'},
{path:'', redirectTo: '', pathMatch: 'full', data: { pagename: 'Home' }},
{
  path: '',
  loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule)
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
